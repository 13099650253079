// Navbar.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FaShoppingCart, FaSearch } from "react-icons/fa";

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const [activeLink, setActiveLink] = useState('');

  const handleLinkClick = (link) => {
    setActiveLink(link);
    closeDropdown(); // Close dropdown after clicking a link
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setSticky(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToAboutUs = () => {
    const aboutUsSection = document.getElementById("aboutus");
    aboutUsSection.scrollIntoView({ behavior: "smooth" });
    closeDropdown();
  };
  const scrollToBenefits = () => {
    const aboutUsSection = document.getElementById("benefits");
    aboutUsSection.scrollIntoView({ behavior: "smooth" });
    closeDropdown();
  };
  const scrollToContacts = () => {
    const aboutUsSection = document.getElementById("contacts");
    aboutUsSection.scrollIntoView({ behavior: "smooth" });
    closeDropdown();
  };

  return (
    <nav
      className={` bg-transparent shadow-md min-w-fit ${isSticky ? "sticky top-0 z-50" : ""}`}
    >
      <div className="container mx-auto px-4 items-start lg:px-1">
        <div className="py-3 flex justify-between items-center">
          <div className="lg:hidden ">
            <button
              onClick={toggleDropdown}
              className="text-[#fff] focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
          <div className="flex ">
            <Link
              to="/"
              className="lg:text-3xl text-xl text-[#fff] flex  -ml-20  lg:ml-5 font-semibold"
            >
              <h1 className=" text-left  font-extrabold">AFHousing</h1>{" "}

            </Link>
          </div>
          <div className="hidden lg:flex space-x-10">
            <Link
              to="/"
              onClick={() => handleLinkClick('home')}
              className={`bg-transparent text-[#fff]  px-4 py-1 font-semibold ${activeLink === 'home' ? 'border-[#fff] border-b text-[#fff] ' : ''
                }`}
            >
              Home
            </Link>
            <Link
              onClick={() => {
                handleLinkClick('contacts');
                scrollToContacts(); // Scroll to benefits section after clicking About Us
              }}
              className={`bg-transparent text-[#fff]  px-4 py-1 font-semibold ${activeLink === 'aboutUs' ? 'border-[#fff] text-[#fff] border-b' : ''
                }`}
            >
              Contact Us
            </Link>
          </div>
          

          {isDropdownOpen && (
            <div className="lg:hidden absolute z-10   top-0 right-0 h-full w-4/5 bg-[#605A59] text-white shadow-md p-4">
              <div className="flex flex-col space-y-6">
                <Link
                  to="/"
                  onClick={closeDropdown}
                  className="block  font-semibold py-2 hover:bg-gray-100 rounded"
                >
                  Home
                </Link>
                <Link
                  onClick={scrollToContacts}
                  className="block  font-semibold py-2 hover:bg-gray-100 rounded"
                >
                  Contact Us
                </Link>
               
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
