import React, { useState } from 'react';
import Navbar from './navbar'
import BG from './images/lastbg.jpg'
import slide1 from './images/bg.jpg'
import slide2 from './images/bg2.jpg'
import slide3 from './images/darkbg.jpg'
import swim1 from './images/swim1.jpg'
import swim2 from './images/swim2.jpg'
import swim3 from './images/swim3.jpg'
import swim4 from './images/swim4.jpg'
import laundry1 from './images/laundry1.jpg'
import laundry2 from './images/laundry2.jpg'
import chef1 from './images/chef1.jpg'
import chef2 from './images/chef2.jpg'
import chef3 from './images/chef3.jpg'
import chef4 from './images/chef4.jpg'
import lux1 from './images/lux1.jpg'
import lux2 from './images/lux2.jpg'
import lux3 from './images/lux3.jpg'
import bar1 from './images/bar1.jpg'
import bar2 from './images/bar2.jpg'
import bar3 from './images/bar3.jpg'
import rec1 from './images/rec1.jpg'
import rec2 from './images/rec2.jpg'
import rec3 from './images/rec3.jpg'
import choose from './images/chose.jpg'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import twitter from './images/twitter.png'
import facebook from './images/facebook.png'
import ig from './images/ig.png'
import { Link } from "react-router-dom";



const Main = () => {
  const [checkIn, setCheckIn] = useState();
  const [checkOut, setCheckOut] = useState();
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendForm = async () => {
    try {
      // const token = sessionStorage.getItem("accessToken");
      setLoading(true);
      const company = "Ailanthus Hospitality Services";

      // Create a FormData object to send the image file
      const newform = {
        company: company,
        name: name,
        email: email,
        message: message,
      };
      console.log(newform);
      // Make the API call
      const response = await fetch(
        "https://af-industries.onrender.com/api/data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newform),
        }
      );

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Failed to add event: ${errorMessage}`);
      }

      // Reset form fields and fetch updated events
      setTimeout(() => {
        setLoading(false);
        setFormSubmitted(true);
        // Reset form fields after submission
        setCompany("");
        setName("");
        setEmail("");
        setMessage("");
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 2000);

    } catch (error) {
      console.error(error.message);
      alert("Failed to add event. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to handle Check-in input change
  const handleCheckInChange = (e) => {
    setCheckIn(e.target.value);
  };


  // Function to handle Check-out input change
  const handleCheckOutChange = (e) => {
    setCheckOut(e.target.value);
  };

  // Function to format date and time for display
  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    return `${formattedTime} (${formattedDate})`;
  };

  const scrollToContacts = () => {
    const aboutUsSection = document.getElementById("contacts");
    aboutUsSection.scrollIntoView({ behavior: "smooth" });
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

  };

  return (
    <div className=' overflow-x-hidden'>
      <div className='h-screen bg-cover bg-center' style={{ backgroundImage: `url(${BG})` }}>
        <Navbar />
        <div>
          <p className='text-white text-center py-60 m-auto  lg:text-3xl font-extrabold'>Let’s help you secure the perfect Luxurious home and give you maximum comfort</p>
        </div>


      </div>

      <div className='m-auto pt-20 text-center'>
        <h1 className='text-sm  mb-4'>
          AILANTHUS LUXURY HOMES
        </h1>
        <h1 className='text-3xl font-semibold mb-4'>
          YOUR LUXURY IS OUR DESIRE
        </h1>
        <hr className=' bg-[#5D4C31] h-2 w-20 mx-auto'></hr>
        <p className=' opacity-80 py-6 leading-relaxed'>
          Where opulence meets tranquility. Nestled amidst breathtaking landscapes, our residences redefine <br /> modern living with a blend of lavish amenities and serene surroundings. Designed with your utmost<br /> comfort in mind, each home exudes sophistication and elegance, promising an unparalleled living<br /> experience.
        </p>
      </div>

      <div className='h-1/4'>
        <Slider {...sliderSettings}>
          <img src={slide1} alt='Slide 1' className='h-1/2' />
          <img src={slide2} alt='Slide 2' className='h-1/2' />
          <img src={slide3} alt='Slide 3' />
        </Slider>
      </div>

      <div className='p-10 w-screen'>
        <h1 className='text-2xl font-bold mb-4 '>Our Features</h1>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          {/* Slider 1 */}
          <div className='w-full'>
            <h2 className='text-xl font-semibold mb-2 px-10'>Swimming Pools</h2>

            <Slider {...sliderSettings} className='rounded-lg px-10 '>
              <img src={swim1} alt='Swimming Pool 1' className='rounded-lg  ' />
              <img src={swim2} alt='Swimming Pool 2' className='rounded-lg' />
              <img src={swim3} alt='Swimming Pool 3' className='rounded-lg' />
              <img src={swim4} alt='Swimming Pool 4' className='rounded-lg' />
            </Slider>
          </div>

          {/* Slider 2 */}
          <div className='w-full'>
            <h2 className='text-xl font-semibold mb-2 px-10'>Laundry Services</h2>
            <Slider {...sliderSettings} className='rounded-lg px-10'>
              <img src={laundry1} alt='Swimming Pool 1' className='rounded-lg' />
              <img src={laundry2} alt='Swimming Pool 2' className='rounded-lg' />

            </Slider>
          </div>

          {/* Additional Sliders */}
          <div className='w-full'>
            <h2 className='text-xl font-semibold mb-2 px-10'>Chef Services</h2>
            <Slider {...sliderSettings} className='rounded-lg px-10'>
              <img src={chef1} alt='Swimming Pool 1' className='rounded-lg' />
              <img src={chef2} alt='Swimming Pool 2' className='rounded-lg' />
              <img src={chef3} alt='Swimming Pool 3' className='rounded-lg' />
              <img src={chef4} alt='Swimming Pool 3' className='rounded-lg' />

            </Slider>
          </div>

          <div className='w-full'>
            <h2 className='text-xl font-semibold mb-2 px-10'>Luxurious amenities</h2>
            <Slider {...sliderSettings} className='rounded-lg px-10'>
              <img src={lux1} alt='Swimming Pool 1' className='rounded-lg' />
              <img src={lux2} alt='Swimming Pool 2' className='rounded-lg' />
              <img src={lux3} alt='Swimming Pool 3' className='rounded-lg' />
            </Slider>
          </div>

          <div className='w-full'>
            <h2 className='text-xl font-semibold mb-2 px-10'>Bar Services</h2>
            <Slider {...sliderSettings} className='rounded-lg px-10'>
              <img src={bar1} alt='Swimming Pool 1' className='rounded-lg' />
              <img src={bar2} alt='Swimming Pool 2' className='rounded-lg' />
              <img src={bar3} alt='Swimming Pool 3' className='rounded-lg' />
            </Slider>
          </div>

          <div className='w-full'>
            <h2 className='text-xl font-semibold mb-2 px-10'>Recreational Services</h2>
            <Slider {...sliderSettings} className='rounded-lg px-10'>
              <img src={rec1} alt='Swimming Pool 1' className='rounded-lg' />
              <img src={rec2} alt='Swimming Pool 2' className='rounded-lg' />
              <img src={rec3} alt='Swimming Pool 3' className='rounded-lg' />
            </Slider>
          </div>
        </div>
      </div>

      <div className='py-10'>
        <h1 className='text-3xl font-bold mb-6 text-center'>Why Choose Us</h1>
        <div className=' lg:flex  items-center'>
          {/* Image Section */}
          <div className='w-full h-full lg:w-3/6 px-4 mb-4 lg:mb-0'>
            <img src={choose} alt='Why Choose Us' className='rounded-2xl w-full' />
          </div>
          {/* Content Section */}
          <div className='w-full lg:w-3/5 px-4 '>
            <div className='mb-4'>
              <h2 className='text-lg font-bold mb-2'>Strong Negotiation Skills</h2>
              <p className='text-sm'>
                Our team possesses strong negotiation skills to ensure you get the best deals and terms for your investments. We
                prioritize your interests and work diligently to achieve favorable outcomes.
              </p>
            </div>
            <div className='mb-4 bg-[#4D4535] text-white rounded-lg p-2 shadow-lg shadow-[#4D4535]'>
              <h2 className='text-lg font-bold mb-2'>Luxurious Comfort</h2>
              <p className='text-sm'>
                Experience unparalleled luxury and comfort with our carefully curated properties. Each space is designed to
                provide a sense of relaxation and indulgence, ensuring a delightful living experience for our clients.
              </p>
            </div>
            <div>
              <h2 className='text-lg font-bold mb-2'>Affordable Prices</h2>
              <p className='text-sm'>
                We offer competitive and affordable prices without compromising on quality. Enjoy the best value for your money
                with our range of premium properties and services, tailored to meet your budget and preferences.
              </p>
            </div>
          </div>

        </div>
      </div>

      <div className='h-screen bg-cover bg-center' style={{ backgroundImage: `url(${BG})` }}>
        <div>
          <p className='text-white text-center pt-60 text-3xl font-extrabold'>What Customers are saying</p>
        </div>
        <Slider {...sliderSettings} className='rounded-lg text-white px-10'>
          <div className='text-center'>
            <h2 className='text-lg font-semibold py-10 mb-4'>
              "Excellent place, very nice services. Indeed gives you the luxury you want. Would definitely come back here! Allanthus luxury homes is great."
            </h2>
            <hr className='bg-white h-2 w-60 mx-auto mb-4'></hr>
            <p className='font-bold'>Mohammed Jasptah</p>
          </div>
          <div className='text-center'>
            <h2 className='text-lg font-semibold py-10 mb-4'>
              "Such a fantastic experience! The attention to detail and the level of luxury provided by Allanthus luxury homes is unmatched. Highly recommend!"
            </h2>
            <hr className='bg-white h-2 w-60 mx-auto mb-4'></hr>
            <p className='font-bold'>Emily Thompson</p>
          </div>
          <div className='text-center'>
            <h2 className='text-lg font-semibold py-10 mb-4'>
              "Absolutely stunning! The ambiance, the service, everything about Allanthus luxury homes is top-notch. Will definitely be back for more."
            </h2>
            <hr className='bg-white h-2 w-60 mx-auto mb-4'></hr>
            <p className='font-bold'>John Doe</p>
          </div>
        </Slider>
      </div>

      <div className='p-10' id='contacts'>
        <h1 className="text-3xl font-bold mb-6 text-center ">Make Enquiries</h1>
        <div className="flex flex-col lg:flex-row items-start lg:items-center">
          <div className="w-full lg:w-3/6 px-4 mb-4 lg:mb-0">
            <img src={slide2} alt="Why Choose Us" className="rounded-2xl w-full" />
          </div>
          <div className="grid gap-4 w-full p-5">
            <label htmlFor="company" className="text-lg font-semibold">Company</label>
            <p id="company" name="company" value={company} onChange={(e) => setCompany(e.target.value)} className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500" >Ailanthus Hospitality Services</p>


            <label htmlFor="name" className="text-lg font-semibold">Name</label>
            <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500" />

            <label htmlFor="email" className="text-lg font-semibold">Email</label>
            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500" />

            <label htmlFor="message" className="text-lg font-semibold">Message</label>
            <textarea id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} rows="5" className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500"></textarea>

            <button onClick={sendForm} className="bg-[#605A59] text-white text-center w-24 py-3 rounded-lg  font-semibold hover:bg-[#9e8f83] transition-colors duration-300" disabled={loading}>
              {loading ? "Loading..." : "Send"}
            </button>
            {formSubmitted && (
              <div className="mt-4 text-center text-green-500">Form submitted successfully!</div>
            )}          </div>
        </div>
      </div>

      <footer id="contacts" className="bg-[#605A59] text-white ">
        <div className="container mx-auto flex flex-col md:flex-row px-6 py-24 md:py-24">
          <div className=" mb-6 md:mb-0 md:mr-8">
            <Link className="text-2xl  flex pb-5 font-semibold">
              <h1 className="font-extrabold">AFHousing</h1>{" "}
            </Link>
            <p className="text-sm  mb-6 opacity-60">
              Experience unparalleled luxury and comfort with our carefully curated properties. Each space is designed to
              provide a sense of relaxation and indulgence, ensuring a delightful living experience for our clients.            </p>
            {/* <p className='flex gap-4'>
              <img
                src={twitter}
                alt="Group 8"
                className=" w-8 h-8"
              />
              <img
                src={facebook}
                alt="Group 8"
                className=" w-8 h-8"
              />
              <img
                src={ig}
                alt="Group 8"
                className=" w-8 h-8"
              />


            </p> */}
          </div>
          {/* <div className=' grid grid-cols-2 lg:flex gap-20'>
            <div className="md:w-1/4 ">
              <h1 className="text-xl font-light mb-4">COMPANY</h1>
              <ul className="text-sm">
                <li className="py-1 font-light  opacity-60">About</li>
                <li className="py-1 font-light  opacity-60">Features</li>
                <li className="py-1 font-light  opacity-60">Works</li>
                <li className="py-1 font-light  opacity-60">Career</li>

              </ul>
            </div>
            <div className="md:w-1/4 mx-auto">
              <h1 className="text-xl font-light mb-4">HELP</h1>
              <ul className="text-sm">
                <li className="py-1 font-light  opacity-60">Account</li>
                <li className="py-1 font-light opacity-60">Delivery Details</li>
                <li className="py-1 font-light  opacity-60">Terms & Conditions</li>
                <li className="py-1 font-light  opacity-60">Privacy Policy</li>

              </ul>
            </div>
            <div className="md:w-1/4">
              <h1 className="text-xl font-light mb-4">FAQ</h1>
              <ul className="text-sm">
                <li className="py-1 font-light  opacity-60">Account</li>
                <li className="py-1 font-light  opacity-60">Manage Deliveries</li>
                <li className="py-1 font-light  opacity-60">Orders</li>
                <li className="py-1 font-light  opacity-60">Payments</li>

              </ul>
            </div>
            <div className="md:w-1/4">
              <h1 className="text-xl font-light mb-4">RESOURCES</h1>
              <ul className="text-sm">
                <li className="py-1 font-light  opacity-60">Free eBooks</li>
                <li className="py-1 font-light  opacity-60">Development Tutorial</li>
                <li className="py-1 font-light  opacity-60">How to - Blog</li>
                <li className="py-1 font-light  opacity-60">Youtube Playlist</li>

              </ul>
            </div>
          </div> */}
        </div>
        <hr className=' bg-white   w-5/6 justify-center m-auto  '></hr>
        <div className='block lg:flex justify-center text-center mx-4 sm:mx-32 py-5'>
          <h1 className=' font-light text-center'>2024 © AFHousing Enterprise Ltd</h1>
        </div>


      </footer>

    </div>
  )
}

export default Main